import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Text,
} from '@theme-ui/components';
import SEO from 'components/atoms/Seo';
import Layout from 'components/organisms/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { Fade } from 'react-awesome-reveal';

const Index = () => {
  const {
    allFile: { nodes: clientLogos },
  } = useStaticQuery(graphql`
    query ClientLogosQuery {
      allFile(
        sort: { fields: name }
        filter: { relativeDirectory: { regex: "/clients/" } }
      ) {
        nodes {
          id
          publicURL
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO />
      <Container>
        <Flex
          sx={{
            justifyContent: 'center',
            alignContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            pb: [5, 5, 5, 5],
            px: 3,
          }}
        >
          <Fade cascade fraction={0.25}>
            <Heading as="h1" sx={{ mb: 5 }}>
              About
            </Heading>
          </Fade>

          <Fade cascade fraction={0.25}>
            <Text sx={{ fontSize: 20 }}>
              High Scream, led by French-born visionary Romain Pissenem, is a
              London-based creative production studio acclaimed for their
              energetic live shows and entertainment spaces. Working with
              multiple high profile artists, venues and developers across the
              globe, High Scream delivers creative and highly-technical
              solutions across show production — raising the bar for club
              design, public art installations and virtual music experience.
            </Text>
          </Fade>
        </Flex>
        <Flex sx={{ flexWrap: 'wrap', pb: 5, '> div': { flexBasis: '25%' } }}>
          {clientLogos.map(
            ({ id, publicURL }: { id: string; publicURL: string }) => (
              <Box>
                <Fade cascade fraction={0.25}>
                  <Image key={id} src={publicURL} />
                </Fade>
              </Box>
            )
          )}
        </Flex>
      </Container>

      <Fade>
        <Box sx={{ maxWidth: 1920, mx: 'auto', px: 3, pt: 3 }}>
          <StaticImage
            src="../images/crew.jpg"
            alt="The crew"
            layout="constrained"
            placeholder="blurred"
          />
        </Box>
      </Fade>
    </Layout>
  );
};

export default Index;
